import $ from 'jquery';

import '@accessible360/accessible-slick';

// Foundation
import foundation from './modules/foundation'; /* eslint-disable-line */


// jquery match height NMP
import jqueryMatchHeight from 'jquery-match-height';

import fancyBox from './modules/fancybox';

import general from './modules/general';
import frontpage from './modules/frontpage';

import gatedContent from './modules/gated-content';

import accordion from './modules/accordion';


document.addEventListener('DOMContentLoaded', function () {

	$( document ).foundation();

	general.init();
	frontpage.init();
	fancyBox.init();
	gatedContent.init();
	accordion.init();

	$('.slick').slick();
	$('.slick-cloned').find('a').removeAttr('data-fancybox'); // remove duplicate fancbox

	$('.slick')
.on('afterChange init', function(event, slick, direction){
    // console.log('afterChange/init', event, slick, slick.$slides);
    // remove all prev/next
    slick.$slides.removeClass('prevSlide').removeClass('nextSlide');

    // find current slide
    for (var i = 0; i < slick.$slides.length; i++)
    {
        var $slide = $(slick.$slides[i]);
        if ($slide.hasClass('slick-current')) {
            // update DOM siblings
            $slide.prev().addClass('prevSlide');
            $slide.next().addClass('nextSlide');
            break;
        }
    }
  }
)
.on('beforeChange', function(event, slick) {
    // optional, but cleaner maybe
    // remove all prev/next
    slick.$slides.removeClass('prevSlide').removeClass('nextSlide');
})
}, false);