import $ from 'jquery';
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

export default {
	init() {
	
	
	function removeDataAttributes(target) {
		var $target = $(target);
		
		// Loop through data attributes.
		$.each($target.data(), function (key) {
			// Because each key is in camelCase,
			// we need to convert it to kabob-case and store it in attr.
			var attr = 'data-' + key.replace(/([A-Z])/g, '-$1').toLowerCase(); 
			// Remove the attribute.
			$target.removeAttr(attr);
		});
	};

		
	/*
     * Create cookies
     */
		function createCookie(name,value,days) {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + value + expires + "; path=/";
		}
		
		/*
		 * Get cookie
		*/
		
		function getCookie(name) {
			var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
			return v ? v[2] : null;
		}
		
		/*
		 * Clear Gravity Form errors
		 */
		function clearGformErrors(element) {
			$(element).find('.gform_wrapper').removeClass('gform_validation_error');
			$(element).find('.validation_error,.validation_message').remove();
			$(element).find('.gfield').removeClass('gfield_error');
		}
				
	
		
		// if "gatedModal" cookie exists, remove all anchors and nothing else matthers
		var gatedModal = getCookie('gatedModal');
		if(gatedModal == 1 || gatedModal == "1"){

			// copy all data-url to href
			$('.gated-pdf').each(function(){
				$(this).attr("href", $(this).attr('data-url') ); // Set herf value
			});

			removeDataAttributes('.gated-pdf');
			$('.gated-content').removeClass('gated-pdf');
			//console.log('remove modal links');
		}
		
		/*
		 * Gated anchor event
		 */
		$(document).on('click', '.gated-pdf',function(e){
			e.preventDefault();
			
			var pdfURL = $(this).attr('data-url');
			createCookie('gatedModalRedirect', pdfURL, 30 );
						
			
		});
				
		
		/*
		 * After ajax form submission, redirect visitor to link, and set cookie so that all future visits will have no modal form
		*/
		
		$(document).on('gform_confirmation_loaded', function(e){
			
			//createCookie('gatedModal',1,7);

			Fancybox.close();

			// here `fancybox` refers to the current instance
			clearGformErrors(e.target);

			createCookie('gatedModal',1,7);

			// copy all data-url to href
			$('.gated-pdf').each(function(){
				$(this).attr("href", $(this).attr('data-url') ); // Set herf value
			});
			
			removeDataAttributes('.gated-pdf');
			$('.gated-content').removeClass('gated-pdf');
			
			var gatedModalRedirect = getCookie('gatedModalRedirect');
			window.open(gatedModalRedirect,"_blank");
			
		});


	},
};
