import $ from 'jquery';

export default {
	init() {


		$('.read-more-container').addClass('screen-reader-text');

		$('.post-image a').attr('aria-hidden', 'true');


		$('.acf-block-past-dinners h3').matchHeight();

		

		/* $('.moreless-button').on("click", function (e) {
			$('.moretext').slideToggle();
			if ($('.moreless-button span').text() == "Read more") {
				$('.moreless-button span').text("Read Less");
				$('.moreless-button').toggleClass('active');
			} else {
				$('.moreless-button span').text("Read More");
			}

			e.preventDefault();
		});
 */
	},
};
